import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "../Assets/css/swiper-slider.css";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import { MDBBtn } from "mdb-react-ui-kit";
import bg1 from "../Assets/img/bg1.jpg";
import bg2 from "../Assets/img/bg2.jpg";
import bg3 from "../Assets/img/bg3.jpg";
import bg5 from "../Assets/img/bg5.jpg";
import { Parallax } from "react-parallax";
import "animate.css/animate.min.css";
import { motion, useAnimation, useScroll } from "framer-motion";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import bg6 from "../Assets/img/bg6.jpg";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import bedroom1 from "../Assets/img/interior/bedroom1.jpg";
import bedroom2 from "../Assets/img/interior/bedroom2.png";
import kitchen1 from "../Assets/img/interior/kitchen1.png";
import kitchen2 from "../Assets/img/interior/kitchen2.png";
import salon1 from "../Assets/img/interior/salon1.png";
import bathroom2 from "../Assets/img/interior/bathroom2.jpeg";
import salon2 from "../Assets/img/interior/salon2.png";

export default function InteriorShowcase() {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      x: 0,
    });
  }, [controls]);

  const handleSlideChange = () => {
    console.log("slide changed");
    controls.start({
      opacity: 0,
      x: 5000,
      transition: { duration: 1 },
    });
    setTimeout(() => {
      controls.start({
        opacity: 1,
        x: 0,
      });
    }, 500);
  };

  return (
    <>
      //{" "}
      <h1 className="interior-section-head" style={{ textAlign: "center" }}>
        Interior Projects Showcase
      </h1>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
          <div>
            <MDBRow className="mt-5">
              <MDBCol size="md">
                <img
                  src={bedroom1}
                  className="showcase-img"
                  style={{ width: "70%" }}
                />
              </MDBCol>
              <MDBCol size="md" style={{ textAlign: "center" }}>
                <h3 style={{ color: "#FFBD59" }}>Beautiful Bedroom Design</h3>
                <p className="interior-showcase-p">
                  <span style={{ color: "#643392", fontWeight: "bold" }}>
                    A Beautiful bedroom design crafted by Yashwardhan Interiors
                    in Sector 110, Gurugram.
                  </span>
                  <br />
                  In the idyllic neighborhood of Sector 110, Gurugram, lies a
                  collection of bedrooms that epitomize tranquility and comfort.
                  This essay explores the exceptional work of an accomplished
                  interior designer who has transformed ordinary sleeping spaces
                  into serene sanctuaries. With a harmonious blend of
                  aesthetics, functionality, and personalized touches, these
                  bedrooms in Sector 110 have become havens of relaxation and
                  rejuvenation. Sector 110, a thriving community in Gurugram,
                  provided the perfect canvas for the interior designer's
                  creative vision. Understanding the importance of a restful
                  retreat, they embarked on a mission to create bedrooms that
                  seamlessly blended style and comfort. With an emphasis on
                  personalized design and attention to detail, the designer
                  sought to create intimate spaces that reflected the unique
                  tastes and personalities of their clients. The interior
                  designer's ability to curate aesthetics played a pivotal role
                  in the transformation of bedrooms in Sector 110. Each space
                  became a reflection of the client's desires, with carefully
                  selected color palettes, textures, and patterns. From
                  contemporary and minimalist designs to cozy and rustic
                  concepts, every bedroom exuded its own distinct ambiance.
                  Thoughtful placement of furniture, art, and decorative
                  elements created a sense of balance and harmony, contributing
                  to a serene atmosphere conducive to relaxation and restful
                  sleep.
                </p>
              </MDBCol>
            </MDBRow>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <MDBRow className="mt-5">
              <MDBCol size="md">
                <img
                  src={kitchen1}
                  className="showcase-img"
                  style={{ width: "70%" }}
                />
              </MDBCol>
              <MDBCol size="md" style={{ textAlign: "center" }}>
                <h3 style={{ color: "#FFBD59" }}>
                  Beautiful Kitchen Interior Design
                </h3>
                <p className="interior-showcase-p">
                  <span style={{ color: "#643392", fontWeight: "bold" }}>
                    A Beautiful Kitchen design crafted by Yashwardhan Interiors
                    in Sector 110, Gurugram.
                  </span>
                  <br />
                  In the heart of Sector 110, Gurugram, where culinary dreams
                  come to life, an exceptional interior designer has crafted
                  kitchens that transcend mere functionality. This essay delves
                  into the world of kitchen interior design, celebrating the
                  remarkable work of a skilled professional who has transformed
                  mundane cooking spaces into breathtaking showcases of
                  elegance, efficiency, and innovation. In the fast-paced,
                  gastronomically inclined city of Gurugram, the interior
                  designer embarked on a journey to reimagine the heart of every
                  home—the kitchen. Armed with a keen eye for detail and an
                  understanding of the evolving needs of modern living, they
                  embarked on a mission to create spaces that seamlessly blend
                  style with functionality. Sector 110 became their canvas, and
                  their designs turned ordinary kitchens into exceptional
                  culinary environments. Drawing inspiration from the vibrant
                  cultural tapestry of Gurugram, the interior designer expertly
                  integrated aesthetics into their kitchen designs. Each project
                  commenced with a thorough understanding of the client's taste
                  and lifestyle, resulting in kitchens that mirrored their
                  unique personalities. From sleek and minimalist designs to
                  rustic and farmhouse-inspired aesthetics, every kitchen became
                  a reflection of the client's individual style, carefully
                  curated with a harmonious blend of colors, textures, and
                  materials.
                </p>
              </MDBCol>
            </MDBRow>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <MDBRow className="mt-5">
              <MDBCol size="md">
                <img
                  src={salon1}
                  className="showcase-img"
                  style={{ width: "70%" }}
                />
              </MDBCol>
              <MDBCol size="md" style={{ textAlign: "center" }}>
                <h3 style={{ color: "#FFBD59" }}>
                  Beautiful Salon Interior Design
                </h3>
                <p className="interior-showcase-p">
                  <span style={{ color: "#643392", fontWeight: "bold" }}>
                    A Beautiful Salon design crafted by Yashwardhan Interiors in
                    Sohna.
                  </span>
                  <br />
                  Nestled in the serene surroundings of Sohna, a place where
                  relaxation meets rejuvenation, stands a remarkable salon that
                  epitomizes elegance and sophistication. This essay explores
                  the exceptional work of an accomplished interior designer who
                  has reshaped the concept of salon spaces in Sohna. From the
                  seamless integration of comfort and style to the thoughtful
                  utilization of space, their designs have transformed this
                  salon into a haven of beauty and tranquility. In the
                  enchanting town of Sohna, known for its therapeutic hot
                  springs and breathtaking landscapes, the interior designer
                  embarked on a transformative journey to create a salon that
                  captured the essence of its surroundings. Drawing inspiration
                  from the natural beauty and serenity of Sohna, the designer
                  crafted an oasis where clients could escape the stresses of
                  everyday life and indulge in luxurious beauty treatments.
                </p>
              </MDBCol>
            </MDBRow>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <MDBRow className="mt-5">
              <MDBCol size="md">
                <img
                  src={bathroom2}
                  className="showcase-img"
                  style={{ width: "70%" }}
                />
              </MDBCol>
              <MDBCol size="md" style={{ textAlign: "center" }}>
                <h3 style={{ color: "#FFBD59" }}>
                  Beautiful Bathroom Interior Design
                </h3>
                <p className="interior-showcase-p">
                  <span style={{ color: "#643392", fontWeight: "bold" }}>
                    A Beautiful Bathroom design crafted by Yashwardhan Interiors
                    in Sector 103, gurugram.
                  </span>
                  <br />
                  Within the vibrant community of Sector 103, Gurugram, an
                  accomplished interior designer has redefined luxury and
                  relaxation in bathroom spaces. Through their remarkable
                  designs, they have transformed ordinary bathrooms into opulent
                  sanctuaries that surpass expectations. With their unwavering
                  commitment to aesthetics, functionality, and innovation, they
                  have set a new standard for bathroom design in Sector 103.
                  Each bathroom creation reflects the designer's dedication to
                  excellence, elevating the bathing experience to new heights of
                  luxury and tranquility. The designer's commitment to
                  innovation and incorporating modern conveniences was evident
                  in every bathroom project. They embraced cutting-edge
                  technologies, such as sensor-activated faucets and smart
                  shower systems, to enhance the user experience and promote
                  water efficiency. Seamless integration of technology, like
                  hidden storage compartments and integrated sound systems,
                  added an element of sophistication and convenience to the
                  bathrooms of Sector 103. The interior designer went above and
                  beyond to create an ambiance of relaxation and serenity in
                  Sector 103's bathrooms. Lighting played a crucial role, with
                  carefully curated fixtures and strategic placement that
                  allowed for both bright and ambient lighting options. The
                  integration of soothing color schemes, subtle textures, and
                  calming elements, such as indoor plants and natural materials,
                  fostered an oasis-like atmosphere where residents could
                  retreat from the outside world and find solace within their
                  own private haven.
                </p>
              </MDBCol>
            </MDBRow>
          </div>
        </SwiperSlide>
      </Swiper>{" "}
    </>
  );
}
