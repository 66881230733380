import React, { useRef, useEffect } from "react";
import "swiper/swiper-bundle.css";
import "../Assets/css/swiper-slider.css";
import { MDBContainer, MDBBtn, MDBRow, MDBCol } from "mdb-react-ui-kit";
import aboutimg from "../Assets/img/aboutimg.jpg";
import "animate.css/animate.min.css";
import { motion, useAnimation, useScroll } from "framer-motion";
import { chooseUsData } from "../Data/data";
import { clientReviews } from "../Data/data";
import Slider from "../Components/Slider";
import InteriorShowcase from "../Components/InteriorShowcase";

export default function Home() {
  const { scrollYProgress } = useScroll();
  const AnimatedElement = ({ delay, children }) => (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, delay }}
    >
      {children}
    </motion.div>
  );

  const ChooseUsItem = ({ imgSrc, delay, subHead, description }) => (
    <MDBCol size="md" className="d-flex flex-column align-items-center">
      <motion.img
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay }}
        src={imgSrc}
        style={{ width: "50%" }}
      />
      <motion.h4
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay }}
        className="choose-sub-head"
      >
        {subHead}
      </motion.h4>
      <motion.p
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay }}
        className="choose-p"
      >
        {description}
      </motion.p>
    </MDBCol>
  );

  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <Slider />
      <hr />
      <InteriorShowcase />
      <hr />
      <div className="mt-5 about-us">
        <h1 style={{ textAlign: "center" }} className="about-head">
          About Us
        </h1>
        <MDBRow className="mt-5">
          <MDBCol size="md">
            <motion.img
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              src={aboutimg}
              style={{ width: "100%" }}
            />
          </MDBCol>
          <MDBCol size="md">
            <motion.p
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="about-p1"
            >
              About YWI
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="about-p2"
            >
              At <strong>Yashwardhan Interiors</strong>, we are a leading
              construction and interior company with a rich legacy of over 20
              years in the industry. With our extensive experience, we have
              successfully completed over 250+ projects, earning the trust of
              our clients and establishing ourselves as a reliable and reputable
              name in the field. Our commitment to delivering excellence is
              reflected in the satisfaction of our more than 500 customers.
              <br /> <br /> We take great pride in exceeding their expectations,
              bringing their visions to life, and creating spaces that inspire
              and captivate. Their trust in our services is a testament to the
              quality of our workmanship and our unwavering dedication to
              customer satisfaction. One of our key strengths lies in our large
              professional team. Comprising skilled architects, engineers,
              designers, and construction experts, our team is the backbone of
              our success. Each member brings their unique expertise and passion
              to every project, ensuring meticulous attention to detail,
              innovative designs, and seamless execution. We understand that
              every project is unique, and we approach each one with a
              personalized and collaborative mindset. <br /> <br /> From the
              initial concept and design stages to the final construction and
              finishing touches, we work closely with our clients, listening to
              their needs and incorporating their ideas. This collaborative
              approach allows us to tailor our services to meet their specific
              requirements and deliver exceptional results that align with their
              vision.
            </motion.p>
          </MDBCol>
        </MDBRow>
      </div>
      <hr />
      <div className="mt-5 choose-us container">
        <h1 style={{ textAlign: "center" }} className="choose-head">
          Why Choose Us ?
        </h1>
        <MDBRow style={{ textAlign: "center" }} className="mt-5 container">
          {chooseUsData.map((item, index) => (
            <ChooseUsItem
              key={index}
              imgSrc={item.imgSrc}
              delay={item.delay}
              subHead={item.subHead}
              description={item.description}
            />
          ))}
        </MDBRow>
      </div>
      <hr />
      <div className="mt-5 container review-container">
        <h1 className="client-head mt-5" style={{ textAlign: "center" }}>
          What our clients say?
        </h1>
        <MDBRow className="mt-5 review-container container">
          {clientReviews.map((clientReviews, index) => (
            <MDBCol
              size="md"
              key={index}
              className="d-flex flex-column align-items-center"
            >
              <AnimatedElement delay={clientReviews.delay}>
                <img
                  src={clientReviews.avatar}
                  alt="avatar"
                  className="avatar-img"
                />
              </AnimatedElement>
              <AnimatedElement delay={clientReviews.delay}>
                <p className="client-name">{clientReviews.name}</p>
              </AnimatedElement>
              <AnimatedElement delay={clientReviews.delay}>
                <img src={clientReviews.stars} className="star-img" />
              </AnimatedElement>
              <AnimatedElement delay={clientReviews.delay}>
                <p className="client-review">{clientReviews.review}</p>
              </AnimatedElement>
            </MDBCol>
          ))}
        </MDBRow>
      </div>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
