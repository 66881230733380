import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import bg4 from "../Assets/img/bg4.jpg";
import { Parallax } from "react-parallax";

export default function About() {
  return (
    <>
      <div>
        <div>
          <h1
            style={{ textAlign: "center" }}
            className="about-page-head mt-4 mb-5"
          >
            {" "}
            Who We Are ?
          </h1>
          <Parallax bgImage={bg4} strength={500}>
            <div style={{ height: 300 }} className="parallax-h about-parallax">
              <>
                <p style={{ textAlign: "center" }} className="container ab-p1">
                  <span style={{ color: "#FFBD59" }}>
                    YW Interiors and Construction
                  </span>{" "}
                  is a reputable company with a rich history spanning over 20
                  years. Since our inception, we have been at the forefront of
                  the construction and interior design industry, delivering
                  exceptional results to our valued clients. With a proven track
                  record and an unwavering commitment to excellence, we have
                  successfully completed over 250 projects, leaving a lasting
                  impression on each and every one.
                </p>
                <p style={{ textAlign: "center" }} className="container ab-p2">
                  At{" "}
                  <span style={{ color: "#FFBD59" }}>
                    YW Interiors and Construction
                  </span>
                  , we believe that the foundation of our success lies in our
                  core values of professionalism, integrity, and innovation. Our
                  team of experienced professionals, including architects,
                  designers, and skilled craftsmen, work seamlessly together to
                  bring our clients' visions to life. We take pride in our
                  ability to turn dreams into reality, creating spaces that are
                  not only aesthetically pleasing but also functional and
                  practical.
                </p>
              </>
            </div>
          </Parallax>
        </div>
        <div className="about-content container mt-5 mb-5 ab-p3">
          <p style={{ textAlign: "center" }}>
            What sets us apart is our unwavering dedication to customer
            satisfaction. We understand that every project is unique, and we
            tailor our approach to meet the specific needs and preferences of
            our clients. From the initial concept and design phase to the final
            touches, we ensure clear communication, attention to detail, and a
            collaborative partnership throughout the entire process. Our goal is
            to exceed expectations and deliver results that not only meet but
            surpass our clients' requirements. Over the years, we have earned a
            reputation for our meticulous attention to detail, uncompromising
            quality, and timely project completion. Our extensive portfolio
            showcases our versatility and expertise in a wide range of
            construction and interior design projects, including residential,
            commercial, and institutional spaces. From luxurious homes to modern
            offices and everything in between, we have left our mark on the
            architectural landscape. As we look towards the future, we remain
            committed to innovation and staying at the forefront of industry
            trends. We continuously invest in the latest technologies and
            materials, ensuring that our clients benefit from the most advanced
            solutions available. Our passion for excellence drives us to
            constantly push boundaries and explore new possibilities in design
            and construction. At{" "}
            <span style={{ color: "#FFBD59" }}>
              YW Interiors and Construction
            </span>
            , our success is measured by the satisfaction of our clients and the
            legacy of our projects. We take pride in the relationships we have
            built and the trust we have earned over the years. As we continue to
            grow and evolve, we remain dedicated to delivering exceptional
            results and creating spaces that inspire and uplift. Experience the
            difference with{" "}
            <span style={{ color: "#FFBD59" }}>
              YW Interiors and Construction
            </span>{" "}
            - a company that combines craftsmanship, innovation, and a
            relentless pursuit of excellence. Join us on this journey as we
            continue to shape the architectural landscape and build a better
            future, one project at a time.
          </p>
        </div>
      </div>
    </>
  );
}
