import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";
import "../Assets/css/swiper-slider.css";

export default function Footer() {
  return (
    <MDBFooter
      className="text-center"
      color="white"
      style={{ background: "#e5e5e5" }}
    >
      <MDBContainer className="p-4" style={{ color: "#643392" }}>
        <section className="mb-4">
          <MDBBtn
            outline
            floating
            className="m-1"
            href="#!"
            role="button"
            style={{ color: "#643392", borderColor: "#643392" }}
          >
            <MDBIcon fab icon="facebook-f" />
          </MDBBtn>

          <MDBBtn
            outline
            floating
            className="m-1"
            href="#!"
            role="button"
            style={{ color: "#643392", borderColor: "#643392" }}
          >
            <MDBIcon fab icon="twitter" />
          </MDBBtn>

          <MDBBtn
            outline
            floating
            className="m-1"
            href="#!"
            role="button"
            style={{ color: "#643392", borderColor: "#643392" }}
          >
            <MDBIcon fab icon="google" />
          </MDBBtn>

          <MDBBtn
            outline
            floating
            className="m-1"
            href="#!"
            role="button"
            style={{ color: "#643392", borderColor: "#643392" }}
          >
            <MDBIcon fab icon="instagram" />
          </MDBBtn>

          <MDBBtn
            outline
            floating
            className="m-1"
            href="#!"
            role="button"
            style={{ color: "#643392", borderColor: "#643392" }}
          >
            <MDBIcon fab icon="linkedin-in" />
          </MDBBtn>

          <MDBBtn
            outline
            floating
            className="m-1"
            href="#!"
            role="button"
            style={{ color: "#643392", borderColor: "#643392" }}
          >
            <MDBIcon fab icon="github" />
          </MDBBtn>
        </section>

        <section className="">
          <form action="">
            <MDBRow className="d-flex justify-content-center">
              <MDBCol size="auto">
                <p className="pt-2">
                  <strong>Sign up for our newsletter</strong>
                </p>
              </MDBCol>

              <MDBCol md="5" start>
                <MDBInput
                  type="email"
                  label="Email address"
                  className="mb-4"
                  style={{ color: "#643392", borderColor: "#643392" }}
                />
              </MDBCol>

              <MDBCol size="auto">
                <MDBBtn outline type="submit" className="mb-4 newsletter-btn">
                  Subscribe
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
        </section>

        <section className="mb-4">
          <p>
            Whether it's a residential, commercial, or industrial project, we
            have the capabilities to handle projects of varying sizes and
            complexities.
          </p>
        </section>

        <section className="">
          <MDBRow>
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                Yashwardhan Constructions & Interiors
              </h6>
              <p>
                Whether it's a residential, commercial, or industrial project,
                we have the capabilities to handle projects of varying sizes and
                complexities.
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Our Services</h6>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!">Construction</a>
                </li>
                <li>
                  <a href="#!">Interior Designing</a>
                </li>
                <li>
                  <a href="#!">Home Renovation</a>
                </li>
                <li>
                  <a href="#!">Residential & Commercial Projects</a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Go To</h6>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!">Home</a>
                </li>
                <li>
                  <a href="#!">About</a>
                </li>
                <li>
                  <a href="#!">Gallery</a>
                </li>
                <li>
                  <a href="#!">Contact Us</a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Gurugram, Haryana, India
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                help@yashwardhaninteriors.in
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> + 91 97175 74896
              </p>
              <p>
                <MDBIcon icon="print" className="me-3" /> -
              </p>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "#643392", color: "white" }}
      >
        © 2023 Copyright:
        <a href="https://yashwardhaninteriors.in/">yashwardhaninteriors.in</a>
      </div>
    </MDBFooter>
  );
}
