import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBSpinner,
} from "mdb-react-ui-kit";
import "../Assets/css/swiper-slider.css";
import Swal from "sweetalert2";
import { API_BASE_URL } from "../utils/appConstant";
import axios from "axios";

export default function Form() {
  const [loading, setLoading] = useState(false);
  const url = "http://emailapi.yashwardhaninteriors.in/emailapi/";

  const [formData, setFormData] = useState({
    fullname: "",
    location: "",
    servicetype: "",
    contactemail: "",
    contactno: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      axios
        .post(url, formData)
        .then(function (response) {
          Swal.fire({
            title: "Message Sent!",
            text:
              "Thank You " +
              formData.fullname +
              "! We will contact you back ASAP !!",
            icon: "success",
            confirmButtonText: "Done!",
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire({
            title: "We are Sorry !",
            text: "Some Technical error Occured !! Try Again Later or Please reach the owner.",
            icon: "error",
            confirmButtonText: "Back",
          });
        });

      setLoading(false);
    }, 3000);

    setFormData({
      fullname: "",
      location: "",
      servicetype: "",
      contactemail: "",
      contactno: "",
      message: "",
    });
  }

  return (
    <form onSubmit={handleSubmit}>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            id="form6Example1"
            label="Full name"
            name="fullname"
            onChange={handleChange}
            value={formData.fullname}
          />
        </MDBCol>
      </MDBRow>

      <MDBInput
        wrapperClass="mb-4"
        id="form6Example3"
        label="What Type of servicetype you want ?"
        name="servicetype"
        onChange={handleChange}
        value={formData.servicetype}
      />
      <MDBInput
        wrapperClass="mb-4"
        id="form6Example4"
        label="Location"
        name="location"
        onChange={handleChange}
        value={formData.location}
      />
      <MDBInput
        wrapperClass="mb-4"
        type="email"
        id="form6Example5"
        label="Contact Email"
        name="contactemail"
        onChange={handleChange}
        value={formData.contactemail}
      />
      <MDBInput
        wrapperClass="mb-4"
        type="tel"
        id="form6Example6"
        label="Contact Phone Number"
        name="contactno"
        onChange={handleChange}
        value={formData.contactno}
      />

      <MDBInput
        wrapperClass="mb-4"
        textarea
        id="form6Example7"
        rows={4}
        label="Message"
        name="message"
        onChange={handleChange}
        value={formData.message}
      />

      <MDBBtn outline className="mb-5 submit-btn" type="submit" block>
        {loading ? (
          <MDBSpinner role="status">
            <span className="visually-hidden">Loading...</span>
          </MDBSpinner>
        ) : (
          "Send Message"
        )}
      </MDBBtn>
    </form>
  );
}
