import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images } from "../Data/images.js";
import video1 from "../Assets/img/interior/videos/video1.mp4";
import video2 from "../Assets/img/interior/videos/video2.mp4";
import video3 from "../Assets/img/interior/videos/video3.mp4";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";

export default function GalleryComponent() {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  const videos = [video1, video2, video3]; // Add more videos as needed

  return (
    <div className="mb-5">
      <h1
        className="gallery-head container mt-5 mb-5"
        style={{ textAlign: "center" }}
      >
        YWI Project Showcase
      </h1>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      <hr />
      <div className="video-section">
        <h1
          className="gallery-video-head container mt-5 mb-5"
          style={{ textAlign: "center" }}
        >
          YWI Videos Showcase
        </h1>
        <MDBContainer>
          <MDBRow>
            {videos.map((video, index) => (
              <MDBCol key={index} sm="6" md="4" lg="3">
                <video
                  className="img-fluid rounded"
                  controls
                  
                  style={{ maxWidth: "100%", height: "100%" }}
                >
                  <source src={video} type="video/mp4" />
                </video>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
}
