import avatarmale from "../Assets/img/avatarmale.png";
import avatarfemale from "../Assets/img/avatarfemale.png";
import star4 from "../Assets/img/star4.png";
import star5 from "../Assets/img/star5.png";
import choose1 from "../Assets/img/choose1.png";
import choose2 from "../Assets/img/choose2.png";
import choose3 from "../Assets/img/choose3.png";
import choose4 from "../Assets/img/choose4.png";
import "../Assets/css/swiper-slider.css";

export const clientReviews = [
  {
    avatar: avatarmale,
    name: "Sarthak Rao",
    stars: star5,
    review:
      '"Amazing work by Yashwardhan Construction and Interior! They completely transformed my living space into a stylish and functional masterpiece. Highly recommended!"',
    delay: 0,
  },
  {
    avatar: avatarmale,
    name: "Nitesh Mathur",
    stars: star5,
    review:
      '"Yashwardhan Interior exceeded my expectations with their exceptional craftsmanship and attention to detail. I am thrilled with the results of my home renovation!"',
    delay: 0.2,
  },
  {
    avatar: avatarfemale,
    name: "Sanjana Seth",
    stars: star4,
    review:
      "\"I'm incredibly impressed with their professionalism and efficiency. They created a beautiful, personalized design for my office that has boosted productivity and employee morale. Couldn't be happier!\"",
    delay: 0.4,
  },
  {
    avatar: avatarmale,
    name: "Raunak",
    stars: star5,
    review:
      '"Kudos to YWI Constructions for their impeccable workmanship and commitment to customer satisfaction. They turned my house into a dream home!"',
    delay: 0.6,
  },
];

export const chooseUsData = [
  {
    imgSrc: choose1,
    subHead: "Residential Projects",
    description:
      "YWI had done more than 100 residential projects in the last 20 years",
    delay: 0,
  },
  {
    imgSrc: choose2,
    subHead: "Commercial Projects",
    description:
      "YWI had done more than 120 Commercial projects in the last 20 years",
    delay: 0.2,
  },
  {
    imgSrc: choose3,
    subHead: "Customer Satisfaction",
    description:
      "YWI have successfully gained a whopping customer satisfaction rate of 95%.",
    delay: 0.4,
  },
  {
    imgSrc: choose4,
    subHead: "Customer Service",
    description: "YWI provides excellent customer service to their customers.",
    delay: 0.6,
  },
];
