import img2 from "../Assets/img/gallery/img2.jpeg"
import img3 from "../Assets/img/gallery/img3.jpeg"
import img5 from "../Assets/img/gallery/img5.jpeg"
import img6 from "../Assets/img/gallery/img6.jpeg"
import img7 from "../Assets/img/gallery/img7.jpeg"
import img8 from "../Assets/img/gallery/img8.jpeg"
import img9 from "../Assets/img/gallery/img9.jpeg"
import img10 from "../Assets/img/gallery/img10.jpeg"
import img11 from "../Assets/img/gallery/img11.jpeg"
import img12 from "../Assets/img/gallery/img12.jpeg"
import img13 from "../Assets/img/gallery/img13.jpeg"
import img14 from "../Assets/img/gallery/img14.jpeg"
import img15 from "../Assets/img/gallery/img15.jpeg"
import img16 from "../Assets/img/gallery/img16.jpeg"
import img17 from "../Assets/img/gallery/img17.jpeg"
import img18 from "../Assets/img/gallery/img18.jpeg"
import img19 from "../Assets/img/gallery/img19.jpeg"
import img20 from "../Assets/img/gallery/img20.jpeg"
import img21 from "../Assets/img/gallery/img21.jpeg"
import img23 from "../Assets/img/gallery/img23.jpeg"
import img24 from "../Assets/img/gallery/img24.jpeg"
import img25 from "../Assets/img/gallery/img25.jpeg"
import img26 from "../Assets/img/gallery/img26.jpeg"
import img27 from "../Assets/img/gallery/img27.jpeg"
import img28 from "../Assets/img/gallery/img28.jpeg"
import img29 from "../Assets/img/gallery/img29.jpeg"
import img30 from "../Assets/img/gallery/img30.jpeg"
import img31 from "../Assets/img/gallery/img31.jpeg"
import img32 from "../Assets/img/gallery/img32.jpeg"
import img33 from "../Assets/img/gallery/img33.jpeg"
import img34 from "../Assets/img/gallery/img34.jpeg"
import img35 from "../Assets/img/gallery/img35.jpeg"
import img36 from "../Assets/img/gallery/img36.jpeg"
import img37 from "../Assets/img/gallery/img37.jpeg"
import img38 from "../Assets/img/gallery/img38.jpeg"
import img39 from "../Assets/img/gallery/img39.jpeg"
import img40 from "../Assets/img/gallery/img40.jpeg"
import img41 from "../Assets/img/gallery/img41.jpeg"
import img42 from "../Assets/img/gallery/img42.jpeg"
import img43 from "../Assets/img/gallery/img43.jpeg"
import img44 from "../Assets/img/gallery/img44.jpeg"
import img45 from "../Assets/img/gallery/img45.jpeg"
import img46 from "../Assets/img/gallery/img46.jpeg"
import img47 from "../Assets/img/gallery/img47.jpeg"
import img48 from "../Assets/img/gallery/img48.jpeg"
import img49 from "../Assets/img/gallery/img49.jpeg"
import img50 from "../Assets/img/gallery/img50.jpeg"
import img51 from "../Assets/img/gallery/img51.jpeg"
import img52 from "../Assets/img/gallery/img52.jpeg"
import img53 from "../Assets/img/gallery/img53.jpeg"
import img54 from "../Assets/img/gallery/img54.jpeg"
import img55 from "../Assets/img/gallery/img55.jpeg"
import img56 from "../Assets/img/gallery/img56.jpeg"
import img57 from "../Assets/img/gallery/img57.jpeg"
import img58 from "../Assets/img/gallery/img58.jpeg"
import img59 from "../Assets/img/gallery/img59.jpeg"
import img60 from "../Assets/img/gallery/img60.jpeg"
import img61 from "../Assets/img/gallery/img61.jpeg"
import img62 from "../Assets/img/gallery/img62.jpeg"
import img63 from "../Assets/img/gallery/img63.jpeg"
import img64 from "../Assets/img/gallery/img64.jpeg"
import img65 from "../Assets/img/gallery/img65.jpeg"
import img66 from "../Assets/img/gallery/img66.jpeg"
import img67 from "../Assets/img/gallery/img67.jpeg"
import img68 from "../Assets/img/gallery/img68.jpeg"
import img69 from "../Assets/img/gallery/img69.jpeg"
import img70 from "../Assets/img/gallery/img70.jpeg"
import img71 from "../Assets/img/gallery/img71.jpeg"
import img72 from "../Assets/img/gallery/img72.jpeg"
import img74 from "../Assets/img/gallery/img74.jpeg"
import img75 from "../Assets/img/gallery/img75.jpeg"
import img76 from "../Assets/img/gallery/img76.jpeg"
import img77 from "../Assets/img/gallery/img77.jpeg"
import img78 from "../Assets/img/gallery/img78.jpeg"
import img79 from "../Assets/img/gallery/img79.jpeg"
import img80 from "../Assets/img/gallery/img80.jpeg"
import img81 from "../Assets/img/gallery/img81.jpeg"
import img82 from "../Assets/img/gallery/img82.jpeg"
import img83 from "../Assets/img/gallery/img83.jpeg"
import img84 from "../Assets/img/gallery/img84.jpeg"
import img85 from "../Assets/img/gallery/img85.jpeg"
import img86 from "../Assets/img/gallery/img86.jpeg"
import img87 from "../Assets/img/gallery/img87.jpeg"

const importedImages = [
  img2,
  img3,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
  img30,
  img31,
  img32,
  img33,
  img34,
  img35,
  img36,
  img37,
  img38,
  img39,
  img40,
  img41,
  img42,
  img43,
  img44,
  img45,
  img46,
  img47,
  img48,
  img49,
  img50,
  img51,
  img52,
  img53,
  img54,
  img55,
  img56,
  img57,
  img58,
  img59,
  img60,
  img61,
  img62,
  img63,
  img64,
  img65,
  img66,
  img67,
  img68,
  img69,
  img70,
  img71,
  img72,
  img74,
  img75,
  img76,
  img77,
  img78,
  img79,
  img80,
  img81,
  img82,
  img83,
  img84,
  img85,
  img86,
  img87,
  
]

export const images = importedImages.map(image => ({
  src: image,
  original: image,
  width: 600,
  height: 500,
  caption: "Project by YWI"
}))
