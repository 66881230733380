import React from "react";
import { ImWhatsapp } from "react-icons/im";

export default function MessageBtn() {
  return (
    <div>
      <a
        href="https://wa.me/919717574896?text=Hello! I would like to get a quote"
        className="float"
      >
        <ImWhatsapp className="my-float" />
      </a>
    </div>
  );
}
