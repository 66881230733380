import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "../Assets/css/swiper-slider.css";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import { MDBBtn } from "mdb-react-ui-kit";
import bg1 from "../Assets/img/bg1.jpg";
import bg2 from "../Assets/img/bg2.jpg";
import bg3 from "../Assets/img/bg3.jpg";
import bg5 from "../Assets/img/bg5.jpg";
import { Parallax } from "react-parallax";
import "animate.css/animate.min.css";
import { motion, useAnimation, useScroll } from "framer-motion";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
const insideStyles = {
  position: "absolute",
  top: "8px",
  right: "12em",
  color: "white",
};
export default function Slider() {
  const controls = useAnimation();
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  useEffect(() => {
    controls.start({
      opacity: 1,
      x: 0,
    });
  }, [controls]);

  const handleSlideChange = () => {
    console.log("slide changed");
    controls.start({
      opacity: 0,
      x: 5000,
      transition: { duration: 1 },
    });
    setTimeout(() => {
      controls.start({
        opacity: 1,
        x: 0,
      });
    }, 500);
  };
  return (
    <>
      <Swiper
        spaceBetween={30}
        effect="fade"
        navigation={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        // onAutoplayTimeLeft={onAutoplayTimeLeft}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper"
        onSlideChange={handleSlideChange}
      >
        <SwiperSlide>
          <Parallax bgImage={bg1} strength={500}>
            <div style={{ height: 500 }} className="parallax-h">
              <motion.div
                initial={{ x: 500 }}
                animate={controls}
                transition={{ duration: 0.5 }}
                className="banner-bg"
              ></motion.div>
              <div className="img-text-wrapper">
                <div style={insideStyles} className="img-text-container">
                  <motion.h1
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="b1h1"
                  >
                    Seeking Interior Designing ?
                  </motion.h1>
                  <motion.h4
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    className="b1p2"
                  >
                    Your search ends here !!
                  </motion.h4>
                  <motion.p
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="b1h2"
                  >
                    Welcome to <span>Yashwardhan</span> Interiors & Constructors
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.8 }}
                    className="b1p3"
                  >
                    From concept development to final construction, we take care
                    of every step, ensuring a cohesive and well-executed project
                    !!
                  </motion.p>
                </div>
              </div>
            </div>
          </Parallax>
        </SwiperSlide>
        <SwiperSlide>
          <Parallax bgImage={bg5} strength={500}>
            <div style={{ height: 500 }} className="parallax-h">
              <motion.div
                initial={{ x: 500 }}
                animate={controls}
                transition={{ duration: 0.5 }}
                className="banner-bg"
              ></motion.div>
              <div className="img-text-wrapper">
                <div style={insideStyles} className="img-text-container">
                  <motion.h1
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="b2h1 b1h1"
                  >
                    Interiors Works
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    className="b2p1"
                  >
                    YW Interiors and Constructions is a renowned company with
                    over 20 years of experience in the field of interior works.
                    They specialize in a wide range of services, including
                    office interiors, kitchen renovations, living room
                    transformations, bedroom makeovers, and themed room designs.
                    With their expertise and attention to detail, YW Interiors
                    and Constructions have successfully delivered exceptional
                    results to countless clients.
                  </motion.p>
                  <motion.div
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <Link to="/gallery">
                      <MDBBtn className="b2b1">
                        Explore Our Work <AiOutlineArrowRight />
                      </MDBBtn>
                    </Link>
                  </motion.div>
                </div>
              </div>
            </div>
          </Parallax>
        </SwiperSlide>
        <SwiperSlide>
          <Parallax bgImage={bg2} strength={500}>
            <div style={{ height: 500 }} className="parallax-h">
              <motion.div
                initial={{ x: 500 }}
                animate={controls}
                transition={{ duration: 0.5 }}
                className="banner-bg"
              ></motion.div>
              <div className="img-text-wrapper">
                <div style={insideStyles} className="img-text-container">
                  <motion.h1
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="b2h1 b1h1"
                  >
                    Construction Works
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    className="b2p1"
                  >
                    With over 20 years of experience in the construction
                    industry, Yashwardhan Constructions is your trusted partner
                    for all your construction needs. Our seasoned team of
                    professionals brings a wealth of knowledge and expertise to
                    every project, ensuring superior craftsmanship and attention
                    to detail. We take pride in our skilled workers who are not
                    only highly trained but also committed to delivering
                    exceptional results.
                  </motion.p>
                  <motion.div
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <MDBBtn className="b2b1">
                      Get a Call Back <AiOutlineArrowRight />
                    </MDBBtn>
                  </motion.div>
                </div>
              </div>
            </div>
          </Parallax>
        </SwiperSlide>
        <SwiperSlide>
          <Parallax bgImage={bg3} strength={500} className="parallax">
            <div style={{ height: 500 }} className="parallax-h">
              <motion.div
                initial={{ x: 500 }}
                animate={controls}
                transition={{ duration: 0.5 }}
                className="banner-bg"
              ></motion.div>
              <div className="img-text-wrapper">
                <div style={insideStyles} className="img-text-container">
                  <motion.h1
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="b3h1 b1h1"
                  >
                    Highly Skilled workers
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    className="b2p1"
                  >
                    At Yashwardhan Interiors, we take great pride in our highly
                    trained and skilled workforce, which encompasses experts in
                    both construction and interior design. Our team is composed
                    of dedicated professionals who have undergone rigorous
                    training and possess extensive knowledge in their respective
                    fields.
                  </motion.p>
                  <motion.div
                    initial={{ opacity: 0, x: 500 }}
                    animate={controls}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <MDBBtn className="b2b1">
                      Get a Call Back <AiOutlineArrowRight />
                    </MDBBtn>
                  </motion.div>
                </div>
              </div>
            </div>
          </Parallax>
        </SwiperSlide>
      </Swiper>
      ;
    </>
  );
}
