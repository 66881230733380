import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from "mdb-react-ui-kit";
import "../Assets/css/header.css";
import logo from "../Assets/img/logo.png";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  const [homeActive, setHomeActive] = useState(false);
  const [aboutActive, setaboutActive] = useState(false);
  const [galleryActive, setGalleryActive] = useState(false);
  const [contactActive, setContactActive] = useState(false);
  const [showBasic, setShowBasic] = useState(false);

  useEffect(() => {
    setHomeActive(true);
  }, []);

  return (
    <MDBNavbar expand="lg" light bgColor="light">
      <MDBContainer fluid>
        <MDBNavbarBrand href="#">
          <img src={logo} style={{ width: "5em" }} alt="brand" />
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="justify-content-center mb-2 mb-lg-0">
            <MDBNavbarItem>
              <MDBNavbarLink
                aria-current="page"
                href="#"
                className={homeActive ? "active" : ""}
                onClick={() => {
                  setHomeActive(true);
                  setaboutActive(false);
                  setGalleryActive(false);
                  setContactActive(false);
                }}
              >
                <NavLink to="/">Home</NavLink>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink
                href="#"
                className={aboutActive ? "active" : ""}
                onClick={() => {
                  setHomeActive(false);
                  setaboutActive(true);
                  setGalleryActive(false);
                  setContactActive(false);
                }}
              >
                <NavLink to="/about">About Us</NavLink>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink
                href="#"
                className={galleryActive ? "active" : ""}
                onClick={() => {
                  setHomeActive(false);
                  setaboutActive(false);
                  setGalleryActive(true);
                  setContactActive(false);
                }}
              >
                <NavLink to="Gallery">Gallery</NavLink>
              </MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink
                href="#"
                className={contactActive ? "active" : ""}
                onClick={() => {
                  setHomeActive(false);
                  setaboutActive(false);
                  setGalleryActive(false);
                  setContactActive(true);
                }}
              >
                <NavLink to="contact">Contact</NavLink>
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>

          <Link to="/contact">
            <MDBBtn className="quote-btn"> Get a Quote Now</MDBBtn>
          </Link>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
