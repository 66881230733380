import React from "react";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import Home from "../Screens/Home";
import Header from "../Components/Header";
import About from "../Screens/About";
import GalleryComponent from "../Screens/Gallery";
import Contact from "../Screens/Contact";
import Footer from "../Components/Footer";
import MessageBtn from "../Components/MessageBtn";

function Router() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<GalleryComponent />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
      <MessageBtn />
    </BrowserRouter>
  );
}

export default Router;
