import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import React from "react";
import Form from "../Components/Form";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";

export default function Contact() {
  return (
    <>
      <div>
        <h1 className="contact-head mt-5" style={{ textAlign: "center" }}>
          Connect with Our Experts
        </h1>
      </div>
      <div className="container mt-5 mb-5">
        <MDBRow>
          <MDBCol style={{ lineHeight: "5em" }}>
            <div>
              <h2 className="con-h1">Questions ?</h2>
              <p className="con-p1">
                YWI provides fast responses and delivers excellent customer
                service!{" "}
              </p>
              <h2 className="con-h2">Reach us directly !</h2>
              <p className="con-p1">
                <FiPhoneCall /> +91 97175 74896
              </p>
              <p className="con-p1">
                <AiOutlineMail /> help@yashwardhaninteriors.in
              </p>
            </div>
          </MDBCol>
          <MDBCol>
            <Form />
          </MDBCol>
        </MDBRow>
        <hr />
        <div>
          <h2 style={{ textAlign: "center" }} className="contact-head">
            Visit Us
          </h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14023.3846173054!2d76.97332330084741!3d28.51427606166857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d10cbed98f2a7%3A0x46b0b1552826ef8f!2sSector%20108%2C%20Gurugram%2C%20Haryana!5e0!3m2!1sen!2sin!4v1684135676543!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="mt-5"
          ></iframe>
        </div>
      </div>
    </>
  );
}
